import { useStaticQuery, graphql } from "gatsby"
export const  usePackagesData = () => {
  const data = useStaticQuery(
    graphql`
      {
        pagesPackagesYaml {
          heading {
            headingText
            subHeadingText
            subHeadingMaxWidth
          }
          packages {
            content
          }
        }
      }
    `
  )
  return data.pagesPackagesYaml
}